'use strict';

const CONST = require('plugin_frontend_core/constants');
const $doc = $(document);

const selectors = {
    newsletterForm: '.js-footer-newsletter-form',
    newsletterButton: '.js-footer-newsletter-form-button'
};

// Hide the error-message div when the script loads
$('.error-message-footer').hide();

/**
 * Will try to subscribe to the newsletter
 * @param {Object} tokenData - The tokenData with the OAuth Token
 * @param {Event} event - The submit event
 */
function subscribeToNewsletter(event) {
    const $form = $(event.target);
    const formData = $form.serialize();
    const url = $form.attr('action');
    $.ajax({
        type: 'POST',
        url: url,
        data: formData,
        dataType: 'json',
        success: newsletterSubscriptionSuccess,
        error: newsletterSubscriptionError
    });
}

/**
 * Will be fired when the newsletter form is submitted
 * @param {Event} event - The submit event
 */
function onNewsletterFormSubmit(event) {
    event.preventDefault();
    if (!event.target.checkValidity()) {
        event.target.reportValidity();
        return;
    }
    $.spinner().start();
    subscribeToNewsletter(event);
}

/**
 * newsletterSubscriptionSuccess
 * @param {Object} data - The success data
 */
function newsletterSubscriptionSuccess(data) {
    $.spinner().stop();

    if (!data.success && data.errorMessage) {
        $('.error-message-footer').show();
        $('.error-message-text-footer').text(data.errorMessage);
    } else {
        $(selectors.newsletterButton).attr(CONST.attributes.attributes, true);

        if (data.message) {
            window.pubSub.publish(CONST.pubSubEvents.showNotification, {
                msg: data.message
            });
        }
    }
}

/**
 * newsletterSubscriptionError
 * @param {Object} data - The error data
 */
function newsletterSubscriptionError(data) {
    console.error('newsletterSubscriptionError', data);
    $.spinner().stop();
}

module.exports = function () {
    $doc.on(CONST.events.submit, selectors.newsletterForm, function (e) {
        onNewsletterFormSubmit(e);
    });
};
