'use strict';

$(document).ready(function () {
    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }

    function setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }

    /**
     * Send schuurman Id to backend if present in url to set the customer group custom attribute
     * @param {*} schuurmanId
     */
    function sendSchuurmanIdToBackend(schuurmanId) {
        var lastSubmit = getCookie('snLastSubmit');
        if (lastSubmit) {
            lastSubmit = lastSubmit.replace(/^"|"$/g, '');
        }
        var useMarketingCookies = getCookie('useMarketingCookies');
        var currentTime = new Date().getTime();
        var lastSubmitTime = lastSubmit ? new Date(lastSubmit).getTime() : 0;

        // Check if last submit was more than an hour ago
        if (!useMarketingCookies || !lastSubmit || (currentTime - lastSubmitTime > 3600000)) {
            var handlerUrl = 'sfUrls' in window && window.sfUrls.persona.targetGroup;
            if (handlerUrl) {
                $.ajax({
                    url: handlerUrl,
                    type: 'POST',
                    contentType: 'application/json',
                    data: JSON.stringify({ schuurmanId: schuurmanId }),
                    success: function(response) {
                        var now = new Date();
                        setCookie('snLastSubmit', now.toUTCString(), 365); // 365 days for cookie expiration
                        if (response.targetGroup) {
                            console.log('Target group set:' + response.targetGroup);
                        }
                    },
                    error: function(err) {
                        console.log('Error on set target group: ' + err);
                    }
                });
            }
        }
    }

    // Extract Schuurman ID from URL
    var urlParams = new URLSearchParams(window.location.search);
    var schuurmanId = urlParams.get('sn');

    if (schuurmanId) {
        setCookie('sn', schuurmanId, 365); // 365 days before the cookie expires
        sendSchuurmanIdToBackend(schuurmanId);
    } else {
        schuurmanId = getCookie('sn');
        if (schuurmanId) {
            sendSchuurmanIdToBackend(schuurmanId);
        }
    }
});
