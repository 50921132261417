const processInclude = require('app_storefront_base/util');

window.onjQueryLoad(function () {
    // Header components
    processInclude(require('./components/header/menu'));
    processInclude(require('./components/header/minicart'));
    processInclude(require('./components/header/usps'));
    processInclude(require('./components/header/search'));

    // Recognize customers without log-in https://jiraeu.epam.com/browse/SCHOESFCC-3890
    processInclude(require('./components/personalization/customergroup'));

    // Footer components
    processInclude(require('./components/footer/newsletter'));
}, true);
